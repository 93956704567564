import axios from 'axios';
import { json } from 'react-router-dom';
import authHeader from './auth-header';


//para el hosting debes poner todos en /api/
//para trabajar local es http://localhost:8000/api/


 
//const API_URL = 'http://localhost:8000/api/';

 


 const API_URL = '/api/';
  

class UserService {





  getPublicContent() {
    return axios.get(API_URL + 'all');
  }

  getPublicVotaciones() {
    return axios.get(API_URL + 'comp');
  }

  
  allpelis() {
    return axios.get(API_URL + 'allpelis');
  }


  addcompetencia(e) {
    return axios.post(API_URL + 'addcomp', e, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  retirar(e) {
    return axios.post(API_URL + 'retirar', e,{ headers: authHeader() });

  }




  editcomp(id, e) {
    return axios.put(API_URL + 'addcomp/' + id, e, {
      headers: {
        'Content-Type': 'application/json' // O simplemente omitir este encabezado
      }
    });
  }


  loguearcompetencia(e) {
    return axios.post(API_URL + 'loguearcomp',e);
  }

  getPublicParticipantes(id) {
    return axios.get(API_URL + 'comp/'+id);
  }

  getShowvotos(id) {
    return axios.get(API_URL + 'showvotos/'+id);
  }


  getUserCarrito() {
   
    return axios.get(API_URL + 'ingresos', { headers: authHeader() });

  }




/*   getUserBoard() {
   
    return axios.get(API_URL + 'me', { headers: authHeader() });

  } */


  getUserBoard() {
    return axios.get(API_URL + 'me', { headers: authHeader() })
      .catch((error) => {
       
        if (error.response && error.response.status === 401) {
          // El usuario no está autenticado, actualizar estado de autenticación
         
          console.log("errr al autentificar",error)
return error

        }
      });
  }



  allmiembros() {
   
    return axios.get(API_URL + 'allmiembros', { headers: authHeader() });

  }


  getVisitas() {
   
    return axios.get(API_URL + 'visitas', { headers: authHeader() });

  }



  reportarIngreso(e) {
   

   // console.log(e)
    return axios.post(API_URL + 'ingresos', e);

  }

  enviarvoto(e) {
   

    // console.log(e)
     return axios.post(API_URL + 'votar', e);
 
   }

   enviarlinkvotar(e) {
   

    // console.log(e)
     return axios.post(API_URL + 'linkemail', e,{ headers: authHeader() });
 
   }





  getModeratorBoard() {
    return axios.get(API_URL + 'mod', { headers: authHeader() });
  }

  getAdminBoard() {
    return axios.get(API_URL + 'admin', { headers: authHeader() });
  }

  getadControlPagos() {
    return axios.get(API_URL + 'ad', { headers: authHeader() });
  }

}





export default new UserService();
