import React ,{ Component } from 'react';

import { Routes, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import AuthService from "./services/auth.service";

import Login from "./components/login.component";
import Register from "./components/register.component";
import Home from "./components/home.component";
import Competencia from "./components/competencia.component.js";


import Profile from "./components/profile.component";


import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";
import Carrito from "./components/carrito.component";
import ControlPagos from './components/controlpagos.component';
import Paneladm from './Paneladm.js';
import Enviarcorreos from './components/admincomponents/Enviarcorreos.adm.js';
import Addcompetencias from './components/admincomponents/Addcompetencias.adm.js';
import Addpelicula from './components/admincomponents/Addpelicula.adm.js';
import Addmiembros from './components/admincomponents/Addmiembros.adm.js';



class App extends Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);

    this.state = {
      id: '',
      correo: '',
      codigo: '',
      showModeratorBoard: false,
      showAdminBoard: false,
      currentUser: undefined,
      userReady:false,
      content:null,
      redirect:null,

    };
  }

  componentDidMount() {

    
    EventBus.on("logout", () => {
      this.logOut();
    });
  }

  componentWillUnmount() {
    EventBus.remove("logout");
  }

  logOut() {
    AuthService.logout();
    this.setState({
      currentUser: undefined,
    });
  }

  render() {
    const { currentUser } = this.state;
    return (
      <div className=''>
        <div className="listado ">
          <Routes>
        <Route path="/" element={<Home />} />
      
{/* Ir a competencia directamente a traves del codigo      
 */}        <Route path="/:id" element={<Competencia />} />


          
            <Route path="/home" element={<Home />} />          
       
            <Route path="/adm" element={<Paneladm />} />          

            <Route path="/login" element={<Login />} />
            <Route path="/pagos" element={<Carrito  />} />
            <Route path="/register" element={<Register />} />
            
            <Route path="/profile" element={<Profile />}>
            <Route path="send" element={<Enviarcorreos currentUser={this.state.currentUser} />} />
            <Route path="send" element={<Enviarcorreos currentUser={this.state.currentUser} />} />
            <Route path="addcomp" element={<Addcompetencias />} />
            <Route path="addpeliculas" element={<Addpelicula />} />
            <Route path="miembros" element={<Addmiembros />} />


            </Route>

            <Route path="/ad" element={<ControlPagos />} />
          </Routes>
        </div>

       <AuthVerify logOut={this.logOut}/>


      </div>
    );
  }
}

export default App;
