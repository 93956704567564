import axios from "axios";


 
 //const API_URL = "http://localhost:8000/api/";
 

  
const API_URL = '/api/'; 

 

class AuthService {
  login(username, password) {
    return axios
      .post(API_URL + "login", {
        email: username,
        password
      })
      .then(response => {
    //    console.log("esto es lo que recibe", response.data);

        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
      
        }

        return response.data;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }




  logout() {
    localStorage.removeItem("user");
  }

  register(uuid,name,
  email,
  password,
  cedula,
  telefono,
  telefono2,
  nombrefamilia,
  sexo,
  redes,
  nacimiento,
  discapacidad,
  nacionalidad,
  direccion,

  referencia) {


    return axios.post(API_URL + "register", {uuid,
      name,
      email,
      password,

      cedula,
      telefono,
      telefono2,
      nombrefamilia,
      sexo,
      redes,
      nacimiento,
      discapacidad,
      nacionalidad,
      direccion,

      referencia,
    }).catch((err)=>console.log(err));
  }

  getCurrentUser() {
 
    return JSON.parse(localStorage.getItem('user'));;


  }
}

export default new AuthService();